import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardColumns, Navbar } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { useForm } from 'react-hook-form';

import {
  getFavouritesList,
  addToFavourites,
  deleteFromFavourites,
} from '../shared/store/authSlice.js';
import Loader from '../shared/displayComponents/loader/Loader';
import { createQueryString } from '../shared/utils/utils';
import Player from './player/Player';
import FilterBar from './filterBar/FilterBar';
import {
  fetchPlayers,
  addFilters,
  resetFilters,
  fetchFavs,
  resetPagination,
} from './store/actions';
import './Players.scss';

const Players = () => {
  const { players, currentPage, totalPages, selectedFilters } = useSelector(
    // eslint-disable-next-line no-shadow
    ({ players }) => players,
  );
  const { isLoading } = useSelector(({ shared }) => shared);

  const { userEmail, favouritesPlayers, userRole } = useSelector(
    ({ authorization }) => authorization,
  );

  const { handleSubmit, register, watch, reset } = useForm({
    defaultValues: {
      selectedFilters,
    },
  });
  const pathname = window.location.pathname;

  const changeFavouriteStatus = data => {
    favouritesPlayers.includes(data)
      ? dispatch(deleteFromFavourites(userEmail, data))
      : dispatch(addToFavourites(userEmail, data));
  };

  const filters = watch();
  const totalItems = totalPages * players.length;

  const SubmitFilter = values => {
    dispatch(addFilters(values));
    dispatch(resetPagination());
    const queryString = createQueryString(values);
    pathname === '/available-players'
      ? dispatch(fetchPlayers(queryString))
      : dispatch(fetchFavs(queryString, userEmail));
  };

  const ResetForm = () => {
    const queryString = createQueryString({
      sport: 'Volleyball',
      gender: 'Female',
    });
    dispatch(resetFilters());
    pathname === '/available-players'
      ? dispatch(fetchPlayers(queryString))
      : dispatch(fetchFavs(queryString, userEmail));
  };

  const handlePageClick = value => {
    const queryString = createQueryString({
      page: value,
      ...filters,
    });
    pathname === '/available-players'
      ? dispatch(fetchPlayers(queryString))
      : dispatch(fetchFavs(queryString, userEmail));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const queryString = createQueryString({
      page: currentPage,
      ...selectedFilters,
    });
    pathname === '/available-players'
      ? dispatch(fetchPlayers(queryString, userRole))
      : dispatch(fetchFavs(queryString, userEmail));
    reset(selectedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reset, selectedFilters, pathname, userEmail]);

  useEffect(() => {
    dispatch(getFavouritesList(userEmail));
  }, [dispatch, userEmail]);

  // Filter players based on the 'hidden' property and user role
  const visiblePlayers = players.filter(player => {
    if (userRole === 'admin') {
      return true; // Admin can see all players
    }
    return !player.hidden; // Regular users can't see hidden players
  });

  const isListEmpty = !visiblePlayers.length && !isLoading && (
    <Navbar bg="light">
      <span style={{ fontSize: '1.25rem' }}>
        {pathname === '/available-players'
          ? 'There is currently no match for your search. Please revise your search and try again.'
          : 'You have not added any player to the favorites list.'}
      </span>
    </Navbar>
  );

  return (
    <div className="Player">
      <FilterBar
        watch={watch}
        handleSubmit={handleSubmit}
        SubmitFilter={SubmitFilter}
        ResetForm={ResetForm}
        register={register}
      />
      {isListEmpty}
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <CardColumns className="players__columns">
            {visiblePlayers.map(player => (
              <Player
                key={player._id}
                player={player}
                changeFavouriteStatus={changeFavouriteStatus}
              />
            ))}
          </CardColumns>
          <div className="Player__pagination">
            <Pagination
              hideDisabled
              activePage={currentPage}
              itemsCountPerPage={players.length}
              totalItemsCount={totalItems}
              pageRangeDisplayed={totalPages}
              onChange={handlePageClick}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First"
              lastPageText="Last"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Players;
