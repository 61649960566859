import React from 'react';
import './Loader.scss';
import logo from '../../../assets/brandLogo.png';

const Loader = () => (
  <div className="Loader__container">
    <div className="Loader">
      <img src={logo} alt="Loading" className="Loader__image" />
    </div>
  </div>
);

export default Loader;
