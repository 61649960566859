import React, { useState } from 'react';
import {
  Card,
  Button,
  ListGroup,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import notFound from '../../assets/notFound.png';
import { deletePlayer } from '../store/actions';
import DeletePlayerModal from '../../shared/displayComponents/deletePlayerModal/DeletePlayerModal';

import './Player.scss';

const renderSportIcon = sport => {
  switch (sport) {
    case 'Volleyball':
      return 'fa-volleyball-ball';
    case 'Soccer':
      return 'fa-futbol-o';
    case 'Beach Volleyball':
      return 'fa-volleyball-ball';
    case 'Tennis':
      return 'fa-tennis-o';
    case 'Basketball':
      return 'fa-basketball-ball';
    case 'Swimming':
      return 'fa-swimmer';
    case 'Track & Field':
      return 'fa-running';

    default:
      return '';
  }
};

const Player = ({ player, changeFavouriteStatus }) => {
  const { userRole, favouritesPlayers } = useSelector(
    ({ authorization }) => authorization,
  );

  const {
    _id,
    firstName,
    lastName,
    transfer,
    levelOfPlay,
    position,
    graduationYear,
    height,
    image,
    sport,
  } = player;

  const dispatch = useDispatch();
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  const deletePlayerHandler = () => {
    dispatch(deletePlayer(_id, history));
  };

  const checkIfPhotoExists = () => {
    if (image) {
      const buffer = image.data;
      const b64 = Buffer.from(buffer).toString('base64');
      return `data:image;base64,${b64}`;
    }
    return notFound;
  };

  return (
    <Card>
      {showModal && (
        <DeletePlayerModal
          handleCloseModal={handleCloseModal}
          showModal={showModal}
          deletePlayerHandler={deletePlayerHandler}
        />
      )}
      <Card.Body style={{ padding: '0' }}>
        <Card.Header>
          <OverlayTrigger
            overlay={
              <Tooltip className="favourite-tooltip">
                {!favouritesPlayers.includes(_id)
                  ? 'Add to Favorites!'
                  : 'Remove from Favorites'}
              </Tooltip>
            }
          >
            <i
              className={` ${
                favouritesPlayers.includes(_id)
                  ? 'fas fa-heart favourite'
                  : 'far fa-heart'
              }`}
              onClick={() => changeFavouriteStatus(_id)}
            />
          </OverlayTrigger>
          <span>
            {firstName} {lastName}
          </span>
          {userRole === 'admin' && (
            <>
              <NavLink to={`/edit/${_id}`}>
                <i className="fas fa-edit detailsId_button" />
              </NavLink>

              <i
                onClick={() => {
                  setShowModal(true);
                }}
                className="fas fa-trash-alt deleteId_button "
              />
            </>
          )}
        </Card.Header>
        <Card.Img
          style={{
            padding: '0.7rem',
            cursor: 'pointer',
          }}
          onClick={() => history.push(`available-players/${_id}`)}
          variant="top"
          src={checkIfPhotoExists()}
        />
        {transfer && (
          <ListGroup.Item>
            <i className="fas fa-exchange-alt" />
            <span className="item__title">Transfer</span>
          </ListGroup.Item>
        )}
        <ListGroup variant="flush">
          <ListGroup.Item>
            <i className="fas fa-arrows-alt-v" />
            <span className="item__title"> Height:</span>
            <span className="item__details"> {height}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <i className="fas fa-paper-plane" />
            <span className="item__title"> Level of play: </span>
            <span className="item__details">
              {levelOfPlay?.length &&
                levelOfPlay.map((lvl, index) =>
                  index === levelOfPlay.length - 1 ? lvl : `${lvl},  `,
                )}
            </span>
          </ListGroup.Item>
          {!!position?.length && (
            <ListGroup.Item>
              <i className={`fas ${renderSportIcon(sport)}`} />
              <span className="item__title">
                {sport === 'Track & Field' ? 'Event' : 'Position'}{' '}
              </span>
              <span className="item__details">
                {position && position.length && position.map(el => `${el} `)}
              </span>
            </ListGroup.Item>
          )}
          <ListGroup.Item>
            <i className="fas fa-calendar-alt" />
            <span className="item__title">Available from:</span>
            <span className="item__details"> {graduationYear}</span>
          </ListGroup.Item>
        </ListGroup>
        <Button block>
          <NavLink className="card__link" to={`available-players/${_id}`}>
            See details
          </NavLink>
        </Button>
      </Card.Body>
    </Card>
  );
};

export default Player;
