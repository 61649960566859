import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import Loader from '../../shared/displayComponents/loader/Loader';
import CreatePlayerForm from '../createPlayer/CreatePlayerForm';
import { editPlayer } from './store/actions';
import { fetchPlayer } from '../store/actions';
import notFound from '../../assets/notFound.png';
import { playerPhysicalFeature } from '../createPlayer/selectObjects';

const EditPlayer = ({ match }) => {
  const { player } = useSelector(({ players }) => players);
  const { isLoading } = useSelector(({ shared }) => shared);

  const playerId = match.params.id;

  const { register, handleSubmit, errors, reset, watch, control } = useForm({
    defaultValues: {
      player,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'youtubeUrl',
  });

  const {
    fields: accomplishmentsFields,
    append: accomplishmentsAppend,
    remove: accomplishmentsRemove,
  } = useFieldArray({
    control,
    name: 'accomplishments',
  });

  const selectedSport = watch('sport');
  const history = useHistory();
  const dispatch = useDispatch();
  const multiselectEnglishRef = useRef();
  const multiselectPhysicalsRef = useRef();
  const multiselectAnalysisRef = useRef();
  const multiSelectLevelOfPlayRef = useRef();
  const positionRef = useRef();

  useEffect(() => {
    dispatch(fetchPlayer(playerId));
  }, [dispatch, playerId]);

  useEffect(() => {
    const { image, ...newPlayer } = player;

    reset({
      ...newPlayer,
      ...image,
    });
  }, [player, reset]);

  const updatePlayer = ({ image, ...playerToEdit }) => {
    console.log('dfdfv', playerToEdit);
    const analysis = multiselectAnalysisRef.current.getSelectedItems();
    const physicals = multiselectPhysicalsRef.current.getSelectedItems();
    const englishSkills = multiselectEnglishRef.current.getSelectedItems();
    const levelOfPlay = multiSelectLevelOfPlayRef.current.getSelectedItems();
    const position = positionRef.current.getSelectedItems();
    const formData = {
      formValues: {
        ...playerToEdit,
        englishSkills,
        physicals: physicals.length ? physicals.map(({ key }) => key) : [],
        analysis,
        levelOfPlay,
        position,
      },
      files: image,
    };
    dispatch(editPlayer(playerId, formData, history));
  };

  const physicalFeatures =
    player.physicals &&
    player.physicals.map((obj, i) => ({
      key: player.physicals[i],
      section: playerPhysicalFeature.find(
        feature => feature.key === player.physicals[i],
      ).section,
    }));

  const checkIfPhotoExists = () => {
    if (player.image) {
      const buffer = player.image.data;
      const b64 = Buffer.from(buffer).toString('base64');
      return `data:image;base64,${b64}`;
    }
    return notFound;
  };

  return isLoading ? (
    <Loader />
  ) : (
    <CreatePlayerForm
      fields={fields}
      append={append}
      remove={remove}
      multiselectAnalysisRef={multiselectAnalysisRef}
      multiselectPhysicalsRef={multiselectPhysicalsRef}
      multiselectEnglishRef={multiselectEnglishRef}
      multiSelectLevelOfPlayRef={multiSelectLevelOfPlayRef}
      selectedSport={selectedSport}
      checkIfPhotoExists={checkIfPhotoExists}
      physicalFeatures={physicalFeatures}
      player={player}
      isEditMode
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      updatePlayer={updatePlayer}
      accomplishmentsFields={accomplishmentsFields}
      accomplishmentsAppend={accomplishmentsAppend}
      accomplishmentsRemove={accomplishmentsRemove}
      positionRef={positionRef}
    />
  );
};

export default EditPlayer;
