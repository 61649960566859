import React, { useEffect, useState } from 'react';
import { Col, Form, Navbar, Row, Card } from 'react-bootstrap';
import { Multiselect } from 'multiselect-react-dropdown';
import ReactQuill from 'react-quill';
import {
  levelsOfPlay,
  playerAnalysisFeature,
  playerEnglishSkills,
  playerPhysicalFeature,
} from './selectObjects';
import {
  basketballPositions,
  soccerPositions,
  voleyballPositions,
  TrackAndFieldPositions,
  years,
  genders,
} from '../filterBar/filters';

import 'react-quill/dist/quill.snow.css';
import './CreatePlayerForm.scss';

const CreatePlayerForm = ({
  fields,
  append,
  remove,
  register,
  handleSubmit,
  errors,
  createPlayer,
  selectedSport,
  updatePlayer,
  isEditMode,
  player,
  physicalFeatures,
  checkIfPhotoExists,
  multiselectAnalysisRef,
  multiselectPhysicalsRef,
  multiselectEnglishRef,
  multiSelectLevelOfPlayRef,
  accomplishmentsFields,
  accomplishmentsAppend,
  accomplishmentsRemove,
  positionRef,
}) => {
  const [additionalComments, setAdditionalComments] = useState('');

  useEffect(() => {
    if (!isEditMode) return;
    player.additionalComments &&
      setAdditionalComments(player.additionalComments);
  }, [isEditMode, player]);

  const style = {
    chips: {
      background: '#2e496b',
      fontSize: `${0.7} rem`,
      whiteSpace: 'pre-wrap',
    },
    searchBox: {
      background: 'white',
      position: 'relative',
    },
  };

  const styleEnglish = {
    chips: {
      background: '#2e496b',
      fontSize: `${0.7} rem`,
      color: 'white',
      whiteSpace: 'pre-wrap',
    },
    searchBox: {
      background: 'white',
    },
  };

  const getOptions = () => {
    switch (selectedSport) {
      case 'Volleyball':
        return voleyballPositions;
      case 'Basketball':
        return basketballPositions;
      case 'Soccer':
        return soccerPositions;
      case 'Track & Field':
        return TrackAndFieldPositions;
      default:
        return [];
    }
  };

  const handleCommentsChange = content => {
    setAdditionalComments(content);
  };

  return (
    <div className="createPlayer__container">
      <div className="CreatePlayer">
        <Navbar className="CreatePlayer__title">
          {!isEditMode ? 'ADD PLAYER' : 'EDIT PLAYER'}
        </Navbar>
        <Form
          onSubmit={handleSubmit(data => {
            const formData = {
              ...data,
              additionalComments,
            };

            !isEditMode
              ? handleSubmit(createPlayer(formData))
              : handleSubmit(updatePlayer(formData));
          })}
        >
          <div className="CreatePlayer__form">
            <div className="form__general">
              <div className="general__basic">
                <Row
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  <Col xs={6}>
                    <Form.Check
                      type="checkbox"
                      label="Hide Player"
                      name="hidden"
                      ref={register}
                    />
                  </Col>
                  <Col xs={6}>
                    <Form.Check
                      type="checkbox"
                      label="Transfer"
                      name="transfer"
                      ref={register}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      className={errors.firstName && 'is-invalid'}
                      ref={register}
                      name="firstName"
                    />
                    <p className="validationError">
                      {errors?.firstName?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      className={errors.lastName && 'is-invalid'}
                      ref={register}
                      name="lastName"
                    />
                    <p className="validationError">
                      {errors?.lastName?.message}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Label>Choose gender</Form.Label>
                    <Form.Control
                      className={errors.gender && 'is-invalid'}
                      as="select"
                      ref={register}
                      name="gender"
                    >
                      {genders.map(gen => (
                        <option value={gen} key={gen}>
                          {gen}
                        </option>
                      ))}
                    </Form.Control>
                    <p className="validationError">{errors?.gender?.message}</p>
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Date Of Birth</Form.Label>
                    <Form.Control
                      className={errors.dateOfBirth && 'is-invalid'}
                      ref={register}
                      name="dateOfBirth"
                      type="date"
                    />
                    <p className="validationError">
                      {errors?.dateOfBirth?.message}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      className={errors.email && 'is-invalid'}
                      ref={register}
                      name="email"
                    />
                    <p className="validationError">{errors?.email?.message}</p>
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Phone number</Form.Label>
                    <Form.Control
                      className={errors.phoneNumber && 'is-invalid'}
                      ref={register}
                      name="phoneNumber"
                    />
                    <p className="validationError">
                      {errors?.phoneNumber?.message}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Label>Nationality</Form.Label>
                    <Form.Control
                      className={errors.nationality && 'is-invalid'}
                      ref={register}
                      name="nationality"
                    />
                    <p className="validationError">
                      {errors?.nationality?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Sport</Form.Label>
                    <Form.Control
                      as="select"
                      className={errors.sport && 'is-invalid'}
                      ref={register}
                      name="sport"
                    >
                      <option>Volleyball</option>
                      <option>Beach Volleyball</option>
                      <option>Soccer</option>
                      <option>Swimming</option>
                      <option>Tennis</option>
                      <option>Basketball</option>
                      <option>Track & Field</option>
                    </Form.Control>
                    <p className="validationError">{errors?.sport?.message}</p>
                  </Col>
                </Row>
              </div>
              <div className="general__achievements">
                <Row>
                  <Col xs={6}>
                    <Form.Label>Current school </Form.Label>
                    <Form.Control
                      className={errors.currentSchool && 'is-invalid'}
                      ref={register}
                      name="currentSchool"
                    />
                    <p className="validationError">
                      {errors?.currentSchool?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Current club</Form.Label>
                    <Form.Control
                      className={errors.currentClub && 'is-invalid'}
                      ref={register}
                      name="currentClub"
                    />
                    <p className="validationError">
                      {errors?.currentClub?.message}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Label>Available from</Form.Label>
                    <Form.Control
                      as="select"
                      className={errors.graduationYear && 'is-invalid'}
                      ref={register}
                      name="graduationYear"
                    >
                      {years.map(year => (
                        <option value={year} key={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Control>
                    <p className="validationError">
                      {errors?.graduationYear?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <Form.Label>High school gpa</Form.Label>
                    <Form.Control
                      className={errors.highSchoolGpa && 'is-invalid'}
                      ref={register}
                      name="highSchoolGpa"
                    />
                    <p className="validationError">
                      {errors?.highSchoolGpa?.message}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Label>Sat score</Form.Label>
                    <Form.Control
                      className={errors.satScore && 'is-invalid'}
                      ref={register}
                      name="satScore"
                    />
                    <p className="validationError">
                      {errors?.satScore?.message}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Toefl score</Form.Label>
                    <Form.Control
                      className={errors.toeflScore && 'is-invalid'}
                      ref={register}
                      name="toeflScore"
                    />
                    <p className="validationError">
                      {errors?.toeflScore?.message}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Label>Duolingo score</Form.Label>
                    <Form.Control ref={register} name="duolingoScore" />
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Intended Major</Form.Label>
                    <Form.Control
                      className={errors.intendedMajor && 'is-invalid'}
                      ref={register}
                      name="intendedMajor"
                    />
                    <p className="validationError">
                      {errors?.intendedMajor?.message}
                    </p>
                  </Col>

                  <Col xs={12}>
                    <Form.Label>
                      Accomplishments
                      <i
                        onClick={() => accomplishmentsAppend({})}
                        className="far fa-plus-square add_video"
                      />
                    </Form.Label>
                  </Col>
                  {accomplishmentsFields.map((item, index) => {
                    return (
                      <React.Fragment key={item.id}>
                        <Col xs={11}>
                          <Form.Control
                            as="textarea"
                            className={errors.accomplishments && 'is-invalid'}
                            ref={register()}
                            name={`accomplishments[${index}]`}
                            defaultValue={item.value}
                          />
                          <p className="validationError">
                            {errors?.accomplishments?.message}
                          </p>
                        </Col>
                        <Col xs={1}>
                          <i
                            className="fas fa-trash-alt remove_link"
                            onClick={() => accomplishmentsRemove(index)}
                          />
                        </Col>
                      </React.Fragment>
                    );
                  })}
                </Row>
              </div>
              <div className="general__athletic">
                <Row>
                  <Col xs={6}>
                    <Form.Label>Height</Form.Label>
                    <Form.Control
                      className={errors.height && 'is-invalid'}
                      ref={register}
                      name="height"
                    />
                    <p className="validationError">{errors?.height?.message}</p>
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Weight</Form.Label>
                    <Form.Control
                      className={errors.weight && 'is-invalid'}
                      ref={register}
                      name="weight"
                    />
                    <p className="validationError">{errors?.weight?.message}</p>
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Attack Reach</Form.Label>
                    <Form.Control ref={register} name="attackReach" />
                  </Col>
                  <Col xs={6}>
                    <Form.Label>
                      {selectedSport === 'Track & Field' ? 'Event' : 'Position'}{' '}
                    </Form.Label>
                    <Multiselect
                      selectedValues={isEditMode && player.position}
                      options={getOptions()}
                      isObject={false}
                      displayValue="key"
                      showCheckbox
                      style={styleEnglish}
                      ref={positionRef}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <Form.Label>
                      Video
                      <i
                        onClick={() => append({})}
                        className="far fa-plus-square add_video"
                      />
                    </Form.Label>
                  </Col>

                  {fields.map((item, index) => {
                    return (
                      <React.Fragment key={item.id}>
                        <Col xs={11}>
                          <Form.Control
                            className={errors.youtubeUrl && 'is-invalid'}
                            ref={register()}
                            name={`youtubeUrl[${index}]`}
                            defaultValue={item.value}
                          />
                          <p className="validationError">
                            {errors?.youtubeUrl?.message}
                          </p>
                        </Col>
                        <Col xs={1}>
                          <i
                            className="fas fa-trash-alt remove_link"
                            onClick={() => remove(index)}
                          />
                        </Col>
                      </React.Fragment>
                    );
                  })}

                  {!isEditMode ? (
                    <Col xs={12}>
                      <Form.File
                        type="file"
                        name="image"
                        ref={register}
                        label="Player photo"
                      />
                    </Col>
                  ) : (
                    <>
                      <Col xs={6}>
                        <Form.File
                          name="image"
                          type="file"
                          ref={register}
                          label="Edit player photo"
                        />
                      </Col>
                      <Col xs={6}>
                        <Card.Img
                          variant="top"
                          src={checkIfPhotoExists()}
                          className="image__file"
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            </div>
            <div className="form__evaluation">
              <span className="evaluation__title">Sport Evaluation</span>
              <Row className="evaluation__row">
                <Col xs={12} className="evaluation__col">
                  <Form.Label>Physical</Form.Label>
                  <Multiselect
                    selectedValues={isEditMode && physicalFeatures}
                    options={playerPhysicalFeature}
                    groupBy="section"
                    displayValue="key"
                    showCheckbox
                    selectionLimit={4}
                    isObject
                    closeOnSelect={false}
                    style={style}
                    ref={multiselectPhysicalsRef}
                  />
                </Col>
              </Row>
              <Row className="evaluation__row">
                <Col xs={12} className="evaluation__col">
                  <Form.Label>English Proficiency</Form.Label>
                  <Multiselect
                    selectedValues={isEditMode && player.englishSkills}
                    options={playerEnglishSkills}
                    singleSelect
                    isObject={false}
                    displayValue="key"
                    showCheckbox
                    style={styleEnglish}
                    ref={multiselectEnglishRef}
                  />
                </Col>
              </Row>
              <Row className="evaluation__row">
                <Col xs={12} className="evaluation__col">
                  <Form.Label>Player analysis</Form.Label>
                  <Multiselect
                    selectedValues={isEditMode && player.analysis}
                    options={playerAnalysisFeature}
                    isObject={false}
                    showCheckbox
                    closeOnSelect={false}
                    style={style}
                    getSelectedItemsCount
                    ref={multiselectAnalysisRef}
                  />
                </Col>
              </Row>
              <Row className="evaluation__row">
                <Col xs={12} className="evaluation__col">
                  <Form.Label>Expected Level Of Play</Form.Label>
                  <Multiselect
                    selectedValues={isEditMode && player.levelOfPlay}
                    options={levelsOfPlay}
                    isObject={false}
                    displayValue="key"
                    showCheckbox
                    style={styleEnglish}
                    ref={multiSelectLevelOfPlayRef}
                  />
                </Col>
              </Row>
              <Row className="evaluation__row">
                <Col xs={12} className="evaluation__col">
                  <Form.Label>Additional Comments</Form.Label>
                  <ReactQuill
                    style={{ height: '180px', marginBottom: '1rem' }}
                    className=""
                    value={additionalComments}
                    onChange={handleCommentsChange}
                    theme="snow"
                  />
                </Col>
              </Row>
            </div>
          </div>
          <button type="submit" className="CreatePlayer__button">
            {!isEditMode ? 'ADD PLAYER' : 'EDIT PLAYER'}
          </button>
        </Form>
      </div>
    </div>
  );
};

export default CreatePlayerForm;
