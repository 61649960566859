export const soccerPositions = [
  'Goalkeeper',
  'Right Fullback',
  'Left Fullback',
  'Center Back',
  'Defending/Holding Midfielder',
  'Right Midfielder/Winger',
  'Central/Box-to-Box Midfielder',
  'Striker',
  'Attacking Midfielder/Playmaker',
  'Left Midfielder/Wingers',
];
export const TrackAndFieldPositions = [
  'Long Jump',
  'Triple Jump',
  'High Jump',
  'Pole Vault',
  'Shot Put',
  'Discus',
  'Javelin',
  'Hammer Throw',
  'Sprints',
  'Middle Distance',
  'Long Distance',
  'Hurdles',
  'Relays',
  'Decathlon',
  'Heptathlon',
];

export const basketballPositions = [
  'Small Forward',
  'Power Forward',
  'Center',
  'Shooting Guard',
  'Point Guard',
];

export const years = [2025, 2026, 2027, 2028, 2029, 2030];

export const sports = [
  'Volleyball',
  'Beach Volleyball',
  'Soccer',
  'Swimming',
  'Tennis',
  'Basketball',
  'Track & Field',
];

export const genders = ['Female', 'Male'];

export const voleyballPositions = ['OH', 'RS', 'Libero', 'Setter', 'DS', 'MH'];

export const filters = {
  gender: [...genders],
  years: ['Filter all', ...years],
  positions: {
    volleyball: ['Filter all', ...voleyballPositions],
    soccer: ['Filter all', ...soccerPositions],
    basketball: ['Filter all', ...basketballPositions],
  },
  sports: [...sports],
};

export default filters;
