import React, { useEffect, useState } from 'react';
import {
  Card,
  ListGroup,
  Form,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import * as Yup from 'yup';
import Loader from '../../shared/displayComponents/loader/Loader';
import { fetchPlayer, deletePlayer, sendMessage } from '../store/actions';
import {
  addToFavourites,
  deleteFromFavourites,
} from '../../shared/store/authSlice.js';
import notFound from '../../assets/notFound.png';
import videoNotFound from '../../assets/videoNotFound.jpg';
import { playerPhysicalFeature } from '../createPlayer/selectObjects';
import DeletePlayerModal from '../../shared/displayComponents/deletePlayerModal/DeletePlayerModal';

import './PlayerPage.scss';

const PlayerPage = ({ match, staticPlayer }) => {
  const { player } = useSelector(({ players }) => players);
  const history = useHistory();

  useEffect(() => {
    if (!player?._id) {
      history.push('/available-players');
    }
  }, [player, history]);

  const { userRole, favouritesPlayers, userEmail } = useSelector(
    ({ authorization }) => authorization,
  );
  const { isLoading } = useSelector(({ shared }) => shared);
  const playerId = !staticPlayer && match.params.id;
  const { register, handleSubmit, errors } = useForm({
    validationSchema: Yup.object({
      messagePlayer: Yup.string()
        .required('Cannot send an empty message')
        .label('Message')
        .min(10),
    }),
  });
  const onSubmit = data => {
    const message = { ...data, playerId };
    dispatch(sendMessage(message));
  };

  const [showModal, setShowModal] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);

  const dispatch = useDispatch();
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    !staticPlayer && dispatch(fetchPlayer(playerId));
  }, [dispatch, playerId, staticPlayer]);

  const {
    _id,
    firstName,
    lastName,
    gender,
    dateOfBirth,
    nationality,
    currentSchool,
    currentClub,
    graduationYear,
    highSchoolGpa,
    satScore,
    toeflScore,
    height,
    weight,
    position,
    physicals,
    englishSkills,
    levelOfPlay,
    analysis,
    image,
    youtubeUrl,
    sport,
    accomplishments,
    intendedMajor,
    additionalComments,
    duolingoScore,
    attackReach,
    transfer,
  } = !staticPlayer ? player : staticPlayer;

  const deletePlayerHandler = () => {
    dispatch(deletePlayer(_id, history));
  };

  const changeFavouriteStatus = data => {
    favouritesPlayers.includes(data)
      ? dispatch(deleteFromFavourites(userEmail, data))
      : dispatch(addToFavourites(userEmail, data));
  };

  const checkIfPhotoExists = () => {
    if (!staticPlayer) {
      if (image) {
        const buffer = image.data;
        const b64 = Buffer.from(buffer).toString('base64');
        return `data:image;base64,${b64}`;
      }
      return notFound;
    }
    return staticPlayer.image;
  };
  const checkIfVideoExists = () => {
    if (youtubeUrl) {
      return youtubeUrl.reverse().map((yt, idx) => {
        return (
          <iframe
            /* eslint-disable-next-line react/no-array-index-key */
            key={idx}
            title="player_video"
            className="player__video"
            src={yt}
            frameBorder="0"
            allowFullScreen
          />
        );
      });
    }
    return (
      <Card.Img className="player__video" variant="top" src={videoNotFound} />
    );
  };

  const convertDate = () => {
    const [year, month, day] = dateOfBirth.split('-');
    return [month, day, year].join('-');
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="PlayerPage">
      {showModal && (
        <DeletePlayerModal
          handleCloseModal={handleCloseModal}
          showModal={showModal}
          deletePlayerHandler={deletePlayerHandler}
        />
      )}
      <Card>
        <div className="PlayerPage__name">
          <Card.Header
            style={{
              backgroundColor: '#2e496b',
            }}
          >
            <OverlayTrigger
              overlay={
                <Tooltip className="favourite-tooltip">
                  {!favouritesPlayers.includes(_id)
                    ? 'Add to Favorites!'
                    : 'Remove from Favorites'}
                </Tooltip>
              }
            >
              <i
                className={` ${
                  favouritesPlayers.includes(_id)
                    ? 'fas fa-heart favourite'
                    : 'far fa-heart'
                }`}
                onClick={() => changeFavouriteStatus(_id)}
              />
            </OverlayTrigger>
            {firstName} {lastName}
            {userRole === 'admin' && !staticPlayer ? (
              <>
                <NavLink to={`/edit/${_id}`}>
                  <i className="fas fa-edit detailsId_button" />
                </NavLink>

                <i
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="fas fa-trash-alt deleteId_button "
                />
              </>
            ) : null}
          </Card.Header>
        </div>
        <div className="PlayerPage__basicInfo">
          <div className="basicInfo__photo">
            <Card.Img variant="top" src={checkIfPhotoExists()} />
            <Button
              className="expand__contact-player"
              onClick={() => setShowTextArea(prevShow => !prevShow)}
            >
              <span>Contact this player</span>
            </Button>
            {showTextArea && !staticPlayer && (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Label className="contact_player_label">
                  Message
                </Form.Label>
                <Form.Control
                  className={`contact_player_input ${errors.messagePlayer &&
                    'is-invalid'}`}
                  as="textarea"
                  name="messagePlayer"
                  placeholder="Type your message"
                  ref={register}
                />
                <p className="validationError">
                  {errors?.messagePlayer?.message}
                </p>
                <Button className="exapand__contact-player" type="submit">
                  <span>Send</span>
                </Button>
              </Form>
            )}
          </div>

          <div className="basicInfo__information">
            <Card.Title>
              <i className="fas fa-user" /> Basic information
            </Card.Title>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <b>Date of birth: </b>
                {dateOfBirth && convertDate()}
              </ListGroup.Item>
              <ListGroup.Item>
                <b> Nationality: </b>
                {nationality}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Gender: </b> {gender}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Height: </b>
                {height}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Weight: </b>
                {weight}
              </ListGroup.Item>
              {attackReach && (
                <ListGroup.Item>
                  <b>Attack Reach: </b>
                  {attackReach}
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <b>Sport: </b>
                {sport}
              </ListGroup.Item>
              {position && position.length ? (
                <ListGroup.Item>
                  <b> {sport === 'Track & Field' ? 'Event' : 'Position'}</b>
                  <br />
                  <ul className="physical__features">
                    {position.map((obj, i) => (
                      /* eslint-disable-next-line react/no-array-index-key */
                      <li key={i}>{obj}</li>
                    ))}
                  </ul>
                </ListGroup.Item>
              ) : null}
            </ListGroup>
          </div>
        </div>

        <div className="PlayerPage__sport">
          <div className="sport__school">
            <Card.Title>
              <i className="fas fa-volleyball-ball" /> Club and{' '}
              {transfer ? 'College' : 'School'}
            </Card.Title>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <b>Current {transfer ? 'college' : 'school'}: </b>
                {currentSchool}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Current club: </b>
                {currentClub}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Available from: </b> {graduationYear}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>High school GPA: </b>
                {highSchoolGpa}
              </ListGroup.Item>
              {satScore && (
                <ListGroup.Item>
                  <b>SAT score: </b>
                  {satScore}
                </ListGroup.Item>
              )}
              {toeflScore && (
                <ListGroup.Item>
                  <b>Toefl Score: </b>
                  {toeflScore}
                </ListGroup.Item>
              )}
              {duolingoScore && (
                <ListGroup.Item>
                  <b>Duolingo Score: </b>
                  {duolingoScore}
                </ListGroup.Item>
              )}
              {intendedMajor && (
                <ListGroup.Item>
                  <b>Intended Major: </b>
                  {intendedMajor}
                </ListGroup.Item>
              )}
              {accomplishments && accomplishments.length ? (
                <ListGroup.Item>
                  <b>Accomplishments: </b>
                  <br />
                  <ul className="physical__features">
                    {accomplishments.map((obj, i) => (
                      /* eslint-disable-next-line react/no-array-index-key */
                      <li key={i}>{obj}</li>
                    ))}
                  </ul>
                </ListGroup.Item>
              ) : null}
            </ListGroup>
          </div>
          <div className="sport__evaluation">
            <Card.Title>
              <i className="fas fa-star" /> Player evaluation
            </Card.Title>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <b>Physical </b>
                <br />
                <ul className="physical__features">
                  {physicals &&
                    physicals.length &&
                    physicals.map((obj, i) => {
                      return (
                        /* eslint-disable-next-line react/no-array-index-key */
                        <li className="physical__features" key={i}>
                          <b>
                            {
                              playerPhysicalFeature.find(
                                feature => feature.key === physicals[i],
                              ).section
                            }
                            :
                          </b>
                          <span>{physicals[i]}</span>
                        </li>
                      );
                    })}
                </ul>
              </ListGroup.Item>

              <ListGroup.Item>
                <b> In-person analysis </b>
                <ul className="physical__features">
                  {analysis &&
                    analysis.length &&
                    analysis.map((obj, i) => (
                      /* eslint-disable-next-line react/no-array-index-key */
                      <li key={i}>{obj}</li>
                    ))}
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>English proficiency: </b>
                {englishSkills}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Expected level of play: </b>
                {levelOfPlay?.length &&
                  levelOfPlay.map((lvl, index) =>
                    index === levelOfPlay.length - 1 ? lvl : `${lvl},  `,
                  )}
              </ListGroup.Item>
              {additionalComments && (
                <ListGroup.Item>
                  <b>Additional Comments: </b>
                  <br />
                  <span
                    dangerouslySetInnerHTML={{ __html: additionalComments }}
                  />
                </ListGroup.Item>
              )}
            </ListGroup>
          </div>
        </div>
        {youtubeUrl?.length ? (
          <Card.Title className="video__container">
            <i className="fab fa-youtube" /> Video
          </Card.Title>
        ) : null}
        {checkIfVideoExists()}
      </Card>
    </div>
  );
};
export default PlayerPage;
